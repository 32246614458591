import Head from 'next/head';

import ErrorBlock from '../components/ErrorBlock/ErrorBlock';

function Home() {
  return (
    <div>
      <Head>
        <title>Eagers Seller Web</title>
      </Head>

      <ErrorBlock statusCode={404} />
      {/* 
      <OfferLayout>
        <h1 className="text-white position-absolute">Number: xxx xxx xxx</h1>
      </OfferLayout> */}
    </div>
  );
}

export default Home;
